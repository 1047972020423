<template>
    <div class="grupo">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-card>
            <v-dialog v-model="modalGrupo" max-width="500px" persistent>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-card-title>
                            <span class="text-h5">{{ tituloGrupo }}</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-form ref="frmGrupo" v-model="validoGrupo" lazy-validation>
                                <v-text-field v-model="editGrupo.nombre" :rules="reglaNombreGrupo"
                                    prepend-inner-icon="mdi-format-text" label="Name" outlined clearable required>
                                </v-text-field>
                                <v-autocomplete v-model="editGrupo.usuario" :items="usuarios" :rules="reglaSlcUsuario"
                                    item-value="Uid_usuario" item-text="nombre" prepend-inner-icon="mdi-account-circle"
                                    label="Usuario" outlined required>
                                </v-autocomplete>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" class="mr-4" @click="cancelarGrupo">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :disabled="!validoGrupo" color="success" class="mr-4" @click="guardarGrupo">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalEliminarGrupo" max-width="550px">
                <v-card>
                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                        <v-icon x-large left color="error">
                            mdi-alert-octagon-outline
                        </v-icon>
                        ¿Estas seguro que deceas eliminar este campo?
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cerrarEliminarGrupo">
                            Cancelar
                        </v-btn>
                        <v-btn color="success" @click="confirmarElimGrupo">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- tabla -->
            <tabla :header="headersGrupo" :body="grupos" tituloTabla="GRUPOS" :accionAgregar="true" :exportar="true"
                @recargar="listarGrupo" @agregar="agregarGrupo" @actualizar="editarGrupo" @eliminar="eliminarGrupo" />
            <!-- tabla fin -->
        </v-card>
    </div>
</template>
<style>
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../../components/view/Tabla";
    export default {
        name: "grupo",
        components: {
            tabla
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,
            municipios: [],
            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables grupo 
            UidGrupo: null,
            buscarGrupo: '',
            validoGrupo: true,
            modalGrupo: false,
            modalEliminarGrupo: false,
            headersGrupo: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / Grupo',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Encargado',
                    align: 'start',
                    value: 'persona',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            grupos: [],
            usuarios: [],
            reglaNombreGrupo: [
                v => !!v || 'El nombre del grupo es obligatorio.'
            ],
            reglaSlcUsuario: [v => !!v || 'El usuario es obligatorio'],
            editedGrupoIndex: -1,
            editGrupo: {
                nombre: '',
                usuario: null,
            },
            defaultGrupo: {
                nombre: '',
                usuario: null,
            },
            // fin variables grupo
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal grupo 
            tituloGrupo() {
                return this.editedGrupoIndex === -1 ? 'Nuevo Grupo' : 'Editar Grupo'
            },
        },

        watch: {
            // modal grupo
            modalGrupo(val) {
                val || this.cancelarGrupo()
            },
            modalEliminarGrupo(val) {
                val || this.cerrarEliminarGrupo()
            },
            // fin modal grupo
        },

        created() {
            // listar grupos
            this.listarGrupo()
            this.listarUsuario()
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            async listarUsuario() {
                try {
                    await fetch(`${this.url_api}/persona`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.usuarios = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            // CRUD paises 
            async listarGrupo() {
                try {
                    await fetch(`${this.url_api}/grupo`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.grupos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarGrupo() {
                this.modalGrupo = true
            },

            editarGrupo(grupo) {
                this.editedGrupoIndex = this.grupos.indexOf(grupo)
                this.editGrupo = Object.assign({}, grupo)
                this.modalGrupo = true
                this.UidGrupo = grupo.Uid;

                this.usuarios.forEach(element => {
                    if (element.Uid_usuario == grupo.Uid_usuario) {
                        this.editGrupo.usuario = {
                            Uid_usuario: grupo.Uid_usuario,
                            nombre: grupo.nombre
                        };
                    }
                });
            },

            eliminarGrupo(grupo) {
                this.editedGrupoIndex = this.grupos.indexOf(grupo)
                this.editGrupo = Object.assign({}, grupo)
                this.modalEliminarGrupo = true
                this.UidGrupo = grupo.Uid;
            },

            async confirmarElimGrupo() {
                try {
                    await fetch(`${this.url_api}/grupo/${this.UidGrupo}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato elimando con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.grupos.splice(this.editedGrupoIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarGrupo()
            },

            cancelarGrupo() {
                this.$refs.frmGrupo.reset()
                this.$refs.frmGrupo.resetValidation()
                this.modalGrupo = false
                this.$nextTick(() => {
                    this.editGrupo = Object.assign({}, this.defaultGrupo)
                    this.editedGrupoIndex = -1
                })
            },

            cerrarEliminarGrupo() {
                this.modalEliminarGrupo = false
                this.$nextTick(() => {
                    this.editGrupo = Object.assign({}, this.defaultGrupo)
                    this.editedGrupoIndex = -1
                })
            },

            async guardarGrupo() {
                if (this.$refs.frmGrupo.validate()) {
                    if (this.editedGrupoIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/grupo/${this.UidGrupo}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editGrupo)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.grupos[this.editedGrupoIndex], this.editGrupo);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidGrupo = null;
                    } else {
                        try {
                            await fetch(`${this.url_api}/grupo`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editGrupo)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarGrupo();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarGrupo();
                }
            },
            // fin CRUD grupos
        },
    }
</script>